import React from 'react'
import { Link, graphql } from "gatsby";
import {
  Card,
  CardTitle,
  CardImgOverlay,
  Container,
  Row,
  Col
} from 'reactstrap';
import Helmet from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/pro-light-svg-icons';
import { Picture } from 'react-responsive-picture';

import Layout from '../components/layout';

export default ({ data }) => {
  const generateImages = (node) => {
    const assetPrefix = `assets${node.fields.slug}`;
    return {
      cover: `${assetPrefix}-cover.jpg`,
      small: `${assetPrefix}-144p.gif`,
      medium: `${assetPrefix}-240p.gif`,
      large: `${assetPrefix}-480p.gif`,
    };
  }

  return (
    <Layout hugeMargin>
      <Container>
        <Row>
          <Helmet title="Videos">
            <link rel="canonical" href={`https://wedeography.com`} />
            <meta name="og:url" content={`https://wedeography.com`} />
          </Helmet>
          {data.allVideosJson.edges.map(({ node }, index) => {
            const images = generateImages(node);
            // Remove bottom-most element margin
            const marginBottom = index === (data.allVideosJson.totalCount - 1) ? 0 : 3;
            return (
              <Col key={index} xs={12} lg={6} xl={6} className={`mb-${marginBottom} pl-0 pr-0 pl-md-1 pr-md-1 pl-lg-3 pr-lg-3 pl-xl-4 pr-xl-4`}>
                <Card className="shadow-lg border-0">
                  <Link to={node.fields.slug}>
                    <Picture
                      className="card-img-top w-100"
                      alt={node.title}
                      sources={[
                        {
                          srcSet: images.cover,
                          media: "(max-width: 575px)",
                        },
                        {
                          srcSet: images.medium,
                          media: "(max-width: 991px)",
                        },
                        {
                          srcSet: images.large
                        },
                      ]}
                    />
                    <CardImgOverlay className="h-100 d-flex flex-column justify-content-center">
                      <CardTitle className="text-light text-center text-uppercase font-weight-light">
                        {node.title}
                        <FontAwesomeIcon icon={faAngleDoubleRight} className="ml-1" />
                      </CardTitle>
                    </CardImgOverlay>
                  </Link>
                  {/* <Link to={node.fields.slug} className="btn btn-block btn-dark rounded-0">VIEW</Link> */}
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query {
    allVideosJson (
      sort: { order: DESC, fields: [order] }
    ) {
      totalCount
      edges {
        node {
          title
          year
          client
          youtube_url
          fields {
            slug
          }
        }
      }
    }
  }
`
